<script>
import PushC from './push.vue'

export default {
  name: "update",
  components: {
    PushC
  },
  data: () => {
    return {
      activeKey: 2,
      gsid: 'avg',
      m_list: [],
      itemyiqi_create: {
        'id': 0,
        'range': '',
        'name': '',
        'mark': '',
        'order': 0
      },
      loop_list: [],
      itemyiqi_list: [],
      area6_info: false,
      create_loop: {
        area: '',
        type: 1,
      },
      yp_config: false,
      column_table: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      range_info: {"row": [0, 0], "column": [0, 3]},
      data_info: false,
      data_visible: false,
      data_list_visible: false,
      date_type_value: [1, 1],
      data_list: [],
      check_item_tpl_data: [],
      check_item_item_create: {
        range: '',
        item_id: [],
        value: ''
      },
      check_item_item_list: [],
      data_type_options: [],
      report_item_list: [],
      area_info: {
        th: false,
        tb: false,
        tl: false,
        yp: false,
      },
      table_type: false,
      options: {
        container: 'luckysheet', //luckysheet为容器id
        lang: 'zh',
        title: '',
        showinfobar: false,
        showtoolbar: false,
        showtoolbarConfig: {
          undoRedo: true, //撤销重做，注意撤消重做是两个按钮，由这一个配置决定显示还是隐藏
          font: true, // '字体'
          fontSize: true, // '字号大小'
          bold: true, // '粗体 (Ctrl+B)'
          italic: true, // '斜体 (Ctrl+I)'
          //strikethrough: true, // '删除线 (Alt+Shift+5)'
          underline: true, // '下划线 (Alt+Shift+6)'
          textColor: true, // '文本颜色'
          fillColor: true, // '单元格颜色'
          border: true, // '边框'
          image: true,
          mergeCell: true, // '合并单元格'
          horizontalAlignMode: true, // '水平对齐方式'
          verticalAlignMode: true, // '垂直对齐方式'
          print: false, // '打印'
        },
        column: 50,
        row: 50,
        showstatisticBar: false,
        enableAddRow: false,
        enableAddBackTop: false,
        userInfo: false,
        showsheetbar: false,
        cellRightClickConfig: {
          copy: true, // 复制
          copyAs: false, // 复制为
          paste: true, // 粘贴
          insertRow: true, // 插入行
          insertColumn: true, // 插入列
          deleteRow: true, // 删除选中行
          deleteColumn: false, // 删除选中列
          deleteCell: false, // 删除单元格
          hideRow: false, // 隐藏选中行和显示选中行
          hideColumn: false, // 隐藏选中列和显示选中列
          rowHeight: true, // 行高
          columnWidth: true, // 列宽
          clear: false, // 清除内容
          matrix: false, // 矩阵操作选区
          sort: false, // 排序选区
          filter: false, // 筛选选区
          chart: false, // 图表生成
          image: true, // 插入图片
          link: false, // 插入链接
          data: false, // 数据验证
          cellFormat: false // 设置单元格格式
        },
        sheetFormulaBar: false
      },
      create_info: {
        name: '',
        remark: ''
      },
      page_first: true,
      edit: 1,
      list_search: {
        page: 1,
        search: ''
      }
    }
  },
  watch: {
    $route: {
      handler() {
        if (!this.page_first) this.mountedDo();
      },
      deep: true
    }
  },
  mounted() {
    this.mountedDo();
  },
  methods: {
    getReportItemList() {
      this.$sa0.post({
        url: this.$api('检测报告模板流程获取报告模板单元格数据'), data: {
          id: this.$route.params.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.report_item_list = response.data.list.map((item) => {
              return {
                id: item.id,
                range: item.range,
                item_id: item.item_id.split(',').map((i) => Number(i)),
                value: item.value,
                value_name: item.value_name,
              }
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    mountedDo() {
      this.list_search.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
      this.list_search.search = this.$route.query.search ? this.$route.query.search : '';
      this.edit = this.$route.query.edit ? Number(this.$route.query.edit) : 1;
      // 读取完成之后 开始执行 获取 任务委托单列表的函数
      setTimeout(() => {
        this.page_first = false
      })
      this.getDataTypeList()
      this.getCheckItemList()
      this.getReportItemList()
      this.getItemYiqiList()
      this.getloopinfo()
    },
    getCheckItemList() {
      // 检测报告模板流程获取检测项目列表
      this.$sa0.post({
        url: this.$api('检测报告模板流程获取检测项目列表')
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.check_item_item_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    pushExcelJson(exportJson = false) {
      let options = this.options
      if (exportJson) {
        options.data = exportJson.sheets
      } else {
        if (this.edit === 2) {
          this.routerPush(this.list_search.page, this.list_search.search, this.$route.params.id, 1)
        }
      }
      luckysheet.destroy();
      luckysheet.create(options);
      setTimeout(() => {
        if (this.edit === 2) {
          this.getTplAreaInfo()
        } else {
          this.getTplDataInfo()
        }
      }, 1000)
    },
    getTplDataInfo() {
      this.getTableDataList()
    },
    pushAreaColor() {
      if (this.area_info.th) {
        luckysheet.setRangeFormat("bg", "#ffb53240", {range: this.area_info.th.area_turn})
      }
      if (this.area_info.tb) {
        luckysheet.setRangeFormat("bg", "#038c7d40", {range: this.area_info.tb.area_turn})
      }
      if (this.area_info.tl) {
        luckysheet.setRangeFormat("bg", "#1e91fb40", {range: this.area_info.tl.area_turn})
      }
      if (this.area_info.yp) {
        luckysheet.setRangeFormat("bg", "#fe562d40", {range: this.area_info.yp.area_turn})
        this.getYpSet()
        this.getMlist()
      }
      if (this.area_info.bt) {
        luckysheet.setRangeFormat("bg", "#fe562d40", {range: this.area_info.bt.area_turn})
      }
      if (this.area_info.bb) {
        luckysheet.setRangeFormat("bg", "#fe562d40", {range: this.area_info.bb.area_turn})
      }
    },
    getYpSet() {
      this.$sa0.post({
        url: this.$api('获取样品区域设置'), data: {
          id: this.$route.params.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.yp_config = response.data.info
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getTplAreaInfo() {
      this.$sa0.post({
        url: this.$api('获取报告模板区域设置'), data: {
          id: this.$route.params.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.area_info = response.data
            this.pushAreaColor()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getTplTable() {
      this.$sa0.post({
        url: this.$api('获取报告模板表格结构数据'), data: {
          id: this.$route.params.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let table = JSON.parse(response.data.table)
            this.table_type = response.data.type
            this.pushExcelJson(table ? {sheets: [table]} : false);
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getTplInfo() {
      this.$sa0.post({
        url: this.$api('报告模板基本资料'), data: {
          id: this.$route.params.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            // 按照 接口返回的数据 给本地的变量 赋值
            this.create_info = response.data.info
            this.getTplTable();
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    routerPush(page, search, id, edit) {
      // router 推送
      // page 页数
      // search 搜索名称或者编号
      // id 编辑的 任务ID
      // edit 编辑类型  1-基础信息 2-样品信息
      this.$router.push({
        name: 'jcbgtpl',
        params: {
          id
        },
        query: {
          page, search, edit, time: new Date() / 1
        }
      })
    },
    changeEditType() {
      this.routerPush(this.list_search.page, this.list_search.search, this.$route.params.id, this.edit === 1 ? '2' : '1')
    },
    createTplClick() {
      this.$sa0.post({
        url: this.$api('修改报告模板基本信息'), data: this.create_info
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            // 按照 接口返回的数据 给本地的变量 赋值
            this.routerPush(this.list_search.page, this.list_search.search, this.$route.params.id, this.edit)
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    saveDo() {
      let this_ = this;
      let sheet = luckysheet.getSheet(0)
      this_.$sa0.post({
        url: this_.$api('保存报告模板内容'),
        data: {
          id: this_.$route.params.id,
          content: JSON.stringify(sheet)
        }
      }).then((response) => {
        this_.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('保存成功')
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    saveTableInfo() {
      let this_ = this;
      layer.confirm('保存表格结构可能会影响动态数据和区域的设置', {
        btn: ['保存', '取消'] //按钮
      }, function () {
        this_.saveDo()
      }, function () {
      });
    },
    uploadExcel(e) {
      let files = e.target.files;
      if (files == null || files.length == 0) return layer.msg('请上传文件')
      let name = files[0].name;
      let suffixArr = name.split("."), suffix = suffixArr[suffixArr.length - 1];
      if (suffix != "xlsx") return layer.msg('请上传xlsx文件')
      let this_ = this
      LuckyExcel.transformExcelToLucky(files[0], function (exportJson, luckysheetfile) {
            if (exportJson.sheets == null || exportJson.sheets.length == 0) {
              return layer.msg('请上传xlsx文件')
            }
            this_.pushExcelJson(exportJson)
          }
      )
    },
    setArea(range, type) {
      let data = {
        id: this.$route.params.id,
        type: type,
        area: JSON.stringify(range)
      }
      this.$sa0.post({
        url: this.$api('设置报告模板区域'),
        data: data
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('保存成功')
            this.mountedDo();
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    delArea(type) {
      let data = {
        id: this.$route.params.id,
        type: type,
      }
      this.$sa0.post({
        url: this.$api('删除报告模板区域'),
        data: data
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('删除成功')
            this.mountedDo();
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    setAreaToTh() {
      let range = luckysheet.getRange()
      if (range[0].column[0] !== 0) {
        return layer.msg('表头需要从 A1 开始')
      }
      this.setArea(range[0], 1)
    },
    setAreaToLoop() {
      let range = luckysheet.getRange()
      if (range[0].column[0] !== 0) {
        return layer.msg('表头需要从 A1 开始')
      }
      this.setArea(range[0], 6)
    },
    setAreaToTb() {
      let range = luckysheet.getRange()
      if (range[0].column[0] !== 0) {
        return layer.msg('表尾需要从 A 开始')
      }
      this.setArea(range[0], 2)
    },
    setAreaToBt() {
      let range = luckysheet.getRange()
      if (range[0].column[0] !== 0 || range[0].row[0] !== 0) {
        return layer.msg('固定前页需要从 A1 开始')
      }
      this.setArea(range[0], 5)
    },
    setAreaToBb() {
      let range = luckysheet.getRange()
      if (range[0].column[0] !== 0) {
        return layer.msg('固定尾页需要从 A 开始')
      }
      this.setArea(range[0], 6)
    },
    setAreaToTl() {
      let range = luckysheet.getRange()
      if (range[0].column[0] !== 0) {
        return layer.msg('侧边需要从 A 开始')
      }
      this.setArea(range[0], 3)
    },
    getDataTypeList() {
      this.$sa0.post({
        url: this.$api('报告数据类型列表'),
        data: {
          id: this.$route.params.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.data_type_options = response.data.list
            this.getTplInfo()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    setItemData() {
      let range = luckysheet.getRange()
      this.range_info = range[0]
      this.$sa0.post({
        url: this.$api('获取报告数据'),
        data: {
          id: this.$route.params.id,
          area: JSON.stringify(this.range_info)
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (response.data.info) {
              this.data_info = response.data.info
              this.date_type_value = this.data_info.data_turn;
            } else {
              this.date_type_value = [1, 1, 1];
              this.data_info = {
                id: 0,
                sample_table_id: this.has_info,
                area_turn: this.range_info,
                data_turn: {}
              }
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
      this.data_visible = true
    },
    dateRangeName() {
      let t1 = `${this.column_table[this.data_info.area_turn.column[0]]}${this.data_info.area_turn.row[0] + 1}`;
      let t2 = `${this.column_table[this.data_info.area_turn.column[1]]}${this.data_info.area_turn.row[1] + 1}`;
      if (t1 == t2) {
        return t1;
      } else {
        return `${t1}-${t2}`;
      }
    },
    onDataClose() {
      this.data_visible = false
    },
    getTableDataList(open = false) {
      this.$sa0.post({
        url: this.$api('获取报告数据列表'),
        data: {
          id: this.$route.params.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.data_list = response.data.list
            if (open) {
              this.data_list_visible = true
            } else {
              this.data_list.map((item) => {
                this.pushDataMark(item.data_turn, item.area_turn, this.data_type_options, false)
              })
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    saveDataClick(type) {
      this.$sa0.post({
        url: this.$api('创建报告数据'),
        data: {
          id: this.$route.params.id,
          type: 1,
          area: JSON.stringify(this.range_info),
          data: JSON.stringify(this.date_type_value),
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (type === 2) {
              luckysheet.setRangeMerge("all", this.range_info)
            }
            // this.onDataClose()
            setTimeout(() => {
              this.pushDataMark(this.date_type_value, this.range_info, this.data_type_options)
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    pushDataMark(value, area, mmp, save = true) {
      value = JSON.parse(JSON.stringify(value))
      let item_ = -1;
      mmp.map((item, key) => {
        if (item.value === value[0]) {
          item_ = key
        }
      })
      if (value.length > 1) {
        value.shift()
        this.pushDataMark(value, area, mmp[item_].children, save)
      } else {
        let mark = mmp[item_].label
        luckysheet.setCellValue(area.row[0], area.column[0], {
          m: `[@@ ${mark}]`,
          v: `[@@ ${mark}]`,
        })
        this.onDataClose();
        if (save) {
          this.saveDo()
        }
      }
    },
    onDataListClose() {
      this.data_list_visible = false
    },
    toOneShow() {
      // let show = false
      // show = this.data_info.range_turn.column[0] == this.data_info.range_turn.column[1]
      // if (!show) show = this.data_info.range_turn.row[0] == this.data_info.range_turn.row[1]
      // if (!show) show = this.date_type_value[0] == 1
      // console.log('show', JSON.stringify(this.date_type_value))
      return true
    },
    dateRangeNameForLine(range) {
      let t1 = `${this.column_table[range.column[0]]}${range.row[0] + 1}`;
      let t2 = `${this.column_table[range.column[1]]}${range.row[1] + 1}`;
      if (t1 == t2) {
        return t1;
      } else {
        return `${t1}-${t2}`;
      }
    },
    getMlist() {
      this.$sa0.post({
        url: this.$api('获取公式列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.m_list = response.data.list
            this.gsid = this.m_list[0]['id']
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    saveYpConfig() {
      this.$sa0.post({
        url: this.$api('保存样品区域设置'),
        data: {
          id: this.yp_config.id,
          yp_number: this.yp_config.yp_number,
          yp_content: JSON.stringify(this.yp_config.yp_content_turn),
          gs_content: JSON.stringify(this.yp_config.gs_content_turn),
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('保存成功')
            this.getYpSet()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    delYpNumber() {
      this.yp_config.yp_number = ''
    },
    setYpNumber() {
      let range = luckysheet.getRange()
      if (range.length > 1) return layer.msg('只能选择一个单元格')
      let range_str = JSON.stringify(range[0])
      this.yp_config.yp_number = range_str
    },
    returnYpNumber() {
      if (this.yp_config.yp_number === '') return '';
      let yp_number = JSON.parse(this.yp_config.yp_number)
      // [{"row":[19,20],"column":[0,1]}]
      let t1 = `${this.column_table[yp_number.column[0]]}${yp_number.row[0] + 1}`;
      let t2 = `${this.column_table[yp_number.column[1]]}${yp_number.row[1] + 1}`;
      if (t1 == t2) {
        return t1;
      } else {
        return `${t1}-${t2}`;
      }
    },
    delThisData(item) {
      this.$sa0.post({
        url: this.$api('删除报告数据'),
        data: {
          id: item.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            luckysheet.setCellValue(item.area_turn.row[0], item.area_turn.column[0], {
              m: '',
              v: '',
            })
            this.saveDo()
            setTimeout(() => {
              this.getTableDataList()
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
      // this.$set(this.data_list[key], 'data_turn', this.data_list[key]['data_turn'])
    },
    setAreaToYp() {
      let range = luckysheet.getRange()
      if (range[0].column[0] !== 0) {
        return layer.msg('样品需要从 A 开始')
      }
      this.setArea(range[0], 4)
    },
    saveDataLineItemClick(item) {
      this.$sa0.post({
        url: this.$api('创建原始报告单数据'),
        data: {
          id: this.$route.params.id,
          type: 1,
          area: JSON.stringify(item.area_turn),
          data: JSON.stringify(item.data_turn),
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            setTimeout(() => {
              this.pushDataMark(item.data_turn, item.area_turn, this.data_type_options)
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    delYpcontent(key) {
      let yp_content = JSON.parse(JSON.stringify(this.yp_config.yp_content_turn))
      yp_content.splice(key, 1)
      this.yp_config.yp_content_turn = yp_content
    },
    addYpConfig() {
      this.yp_config.yp_content_turn.push({"number": "", "value": ""})
    },
    setYpcontentNumber(key) {
      let range = luckysheet.getRange()
      if (range.length > 1) return layer.msg('只能选择一个单元格')
      let range_str = JSON.stringify(range[0])
      this.yp_config.yp_content_turn[key].number = range_str
    },
    delYpcontentNumber(key) {
      this.yp_config.yp_content_turn[key].number = ''
    },
    setYpcontentValue(key) {
      let range = luckysheet.getRange()
      if (range.length > 1) return layer.msg('只能选择一个单元格')
      let range_str = JSON.stringify(range[0])
      this.yp_config.yp_content_turn[key].value = range_str
    },
    returnYpcontentXy(xy) {
      if (xy === '') return '';
      let xy_ = JSON.parse(xy)
      let t1 = `${this.column_table[xy_.column[0]]}${xy_.row[0] + 1}`;
      let t2 = `${this.column_table[xy_.column[1]]}${xy_.row[1] + 1}`;
      if (t1 == t2) {
        return t1;
      } else {
        return `${t1}-${t2}`;
      }
    },
    addGscontent() {
      let gsid = this.gsid
      let gsname = ''
      let gs_content = []
      if (gsid === 'avg') {
        gsname = '平均值'
        gs_content = [{
          'xy': '',
          'name': '值',
        }, {
          'xy': '',
          'name': '值',
        }, {
          'xy': '',
          'name': '值',
        }]
      } else {
        let content = [];
        this.m_list.map((item) => {
          if (Number(item.id) === Number(gsid)) {
            gsname = item.name
            item.yinzi.map((i) => {
              content.push({
                'xy': '',
                'name': i.yinzi,
              })
            })
          }
        })
        gs_content = content
      }
      // xy
      this.yp_config.gs_content_turn.push({
        gsid: gsid,
        name: gsname,
        content: gs_content
      })
    },
    delGscontent(gsk) {
      let gs_content_turn = JSON.parse(JSON.stringify(this.yp_config.gs_content_turn))
      gs_content_turn.splice(gsk, 1)
      this.yp_config.gs_content_turn = gs_content_turn
    },
    delYpcontentValue(key) {
      this.yp_config.yp_content_turn[key].value = ''
    },
    delGsXy(xy, gsk) {
      this.yp_config.gs_content_turn[gsk].content[xy].xy = ''
    },
    setGsXy(xy, gsk) {
      let range = luckysheet.getRange()
      if (range.length > 1) return layer.msg('只能选择一个单元格')
      let range_str = JSON.stringify(range[0])
      this.yp_config.gs_content_turn[gsk].content[xy].xy = range_str
    },
    avgAddValue(gsk) {
      this.yp_config.gs_content_turn[gsk].content.push({
        'xy': '',
        'name': '值',
      })
    },
    checkItemItemListUpdate(e) {
      let ids = JSON.parse(JSON.stringify(e))
      this.$sa0.post({
        url: this.$api('检测报告模板流程获取检测项目模板数据'),
        data: {id: ids.pop()}
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.check_item_tpl_data = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    chooseRange() {
      let range = luckysheet.getRange()
      if (range.length > 1) return layer.msg('只能选择一个单元格')
      let range_str = JSON.stringify(range[0])
      this.check_item_item_create.range = range_str
    },
    chooseRangeedit(key) {
      let range = luckysheet.getRange()
      if (range.length > 1) return layer.msg('只能选择一个单元格')
      let range_str = JSON.stringify(range[0])
      this.report_item_list[key].range = range_str
    },
    avgDelValue(gsk, key) {
      let content = JSON.parse(JSON.stringify(this.yp_config.gs_content_turn[gsk].content))
      content.splice(key, 1)
      this.yp_config.gs_content_turn[gsk].content = content
    },
    editItemTplData(key) {
      let info = JSON.parse(JSON.stringify(this.report_item_list[key]))
      this.$sa0.post({
        url: this.$api('检测报告模板流程保存报告模板单元格数据'),
        data: {
          id: info.id,
          range: info.range,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.getReportItemList();
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    delItemTplData(key) {
      let info = JSON.parse(JSON.stringify(this.report_item_list[key]))
      this.$sa0.post({
        url: this.$api('检测报告模板流程删除报告模板单元格数据'),
        data: {
          id: info.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.getReportItemList();
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    addItemTplData() {
      if (!this.check_item_item_create.range) return layer.msg('请选择位置')
      if (!this.check_item_item_create.value) return layer.msg('请选择设定值')
      let ids = JSON.parse(JSON.stringify(this.check_item_item_create.item_id))
      if (!ids.length) return layer.msg('请选择检测项目')
      let data = {
        id: 0,
        range: this.check_item_item_create.range,
        item_id: ids.join(','),
        value: this.check_item_item_create.value,
        tpl_id: this.$route.params.id,
      }
      this.$sa0.post({
        url: this.$api('检测报告模板流程保存报告模板单元格数据'),
        data: data
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.getReportItemList();
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    itemyiqiReadRange() {
      let range = luckysheet.getRange()
      if (range.length > 1) return layer.msg('只能选择一个单元格')
      let range_str = JSON.stringify(range[0])
      this.itemyiqi_create.range = range_str
    },
    getItemYiqiList() {
      this.$sa0.post({
        url: this.$api('检测报告模板：获取仪器回填列表'),
        data: {
          id: this.$route.params.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.itemyiqi_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    itemyiqiListReadRange(key) {
      let range = luckysheet.getRange()
      if (range.length > 1) return layer.msg('只能选择一个单元格')
      let range_str = JSON.stringify(range[0])
      this.itemyiqi_list[key].range = range_str
    },
    getloopinfo() {
      this.$sa0.post({
        url: this.$api('检测报告模板：获取循环区域'),
        data: {
          tpl_id: this.$route.params.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.area6_info = response.data.info
            if (this.area6_info) {
              this.getlooplist()
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    createItemyiqiListRange(key) {
      let item = this.itemyiqi_list[key]
      if (!item.range) return layer.msg('请选择位置')
      if (!item.name) return layer.msg('请输入名称')
      this.$sa0.post({
        url: this.$api('检测报告模板：创建仪器回填节点'),
        data: {
          id: item.id,
          report_tpl_id: this.$route.params.id,
          range: item.range,
          name: item.name,
          mark: item.mark,
          order: item.order,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.getItemYiqiList()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    delItemyiqiListRange(key) {
      let item = this.itemyiqi_list[key]
      this.$sa0.post({
        url: this.$api('检测报告模板：删除仪器回填节点'),
        data: {
          id: item.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.getItemYiqiList()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    createItemyiqiRange() {
      if (!this.itemyiqi_create.range) return layer.msg('请选择位置')
      if (!this.itemyiqi_create.name) return layer.msg('请输入名称')
      this.$sa0.post({
        url: this.$api('检测报告模板：创建仪器回填节点'),
        data: {
          id: this.itemyiqi_create.id,
          report_tpl_id: this.$route.params.id,
          range: this.itemyiqi_create.range,
          name: this.itemyiqi_create.name,
          mark: this.itemyiqi_create.mark,
          order: this.itemyiqi_create.order,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.itemyiqi_create = {
              'id': 0,
              'range': '',
              'name': '',
              'mark': '',
              'order': 0
            }
            this.getItemYiqiList()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    readarealoop() {
      let range = luckysheet.getRange()
      if (range.length > 1) return layer.msg('只能选择一个单元格')
      let range_str = JSON.stringify(range[0])
      this.create_loop.area = range_str
    },
    readarealineloop(key) {
      let range = luckysheet.getRange()
      if (range.length > 1) return layer.msg('只能选择一个单元格')
      let range_str = JSON.stringify(range[0])
      this.loop_list[key].area = range_str
    },
    getlooplist() {
      // 检测报告模板：获取循环区域列表
      this.$sa0.post({
        url: this.$api('检测报告模板：获取循环区域列表'),
        data: {
          tpl_id: this.$route.params.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.loop_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    createarealoop() {
      // create_loop_info
      // 检测报告模板：创建循环区域内容
      this.$sa0.post({
        url: this.$api('检测报告模板：创建循环区域内容'),
        data: {
          tpl_id: this.$route.params.id,
          area: this.create_loop.area,
          type: this.create_loop.type,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.create_loop.area = ''
            this.create_loop.type = 1
            this.getlooplist()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    updatearealoop(item) {
      // create_loop_info
      // 检测报告模板：检测报告模板：修改循环区域内容
      this.$sa0.post({
        url: this.$api('检测报告模板：修改循环区域内容'),
        data: {
          id: item.id,
          area: item.area,
          type: item.type,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.getlooplist()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    delarealoop(item) {
      // create_loop_info
      // 检测报告模板：检测报告模板：修改循环区域内容
      this.$sa0.post({
        url: this.$api('检测报告模板：删除循环区域内容'),
        data: {
          id: item.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.getlooplist()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
  }
}
</script>
<template>
  <div>
    <a-drawer
        width="900px"
        title="动态数据列表"
        placement="right"
        :closable="false"
        :visible="data_list_visible"
        @close="onDataListClose"
    >
      <div>
        <table class="layui-table">
          <colgroup>
            <col width="150">
            <col width="200">
            <col>
          </colgroup>
          <thead>
          <tr>
            <th>单元格</th>
            <th>数据</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,key) in data_list" :key="key">
            <td>{{ dateRangeNameForLine(item.area_turn) }}</td>
            <td>
              <a-cascader style="width: 350px;" :options="data_type_options" v-model="item.data_turn"
                          placeholder="请选择数据类型"/>
            </td>
            <td>
              <a-space>
                <a-button type="primary" size="small" @click="saveDataLineItemClick(item)">保存</a-button>
                <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="delThisData(item)">
                  <template slot="title">
                    <p>确定删除该动态数据？</p>
                  </template>
                  <a-button type="danger" size="small">删除</a-button>
                </a-popconfirm>

              </a-space>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </a-drawer>

    <a-drawer
        width="500px"
        title="设置动态数据"
        placement="right"
        :closable="false"
        :visible="data_visible"
        @close="onDataClose"
    >
      <div v-if="data_info">
        <div class="my-2">
          <a-space>
            <div>
              <b>设置区域：</b>
            </div>
            <div>
              {{ dateRangeName() }}
            </div>
          </a-space>
        </div>
        <div class="my-2">
          <a-space>
            <div><b>数据类型：</b></div>
            <div>
              <a-cascader style="width: 350px;" :options="data_type_options" v-model="date_type_value"
                          placeholder="请选择数据类型"/>
            </div>
          </a-space>
        </div>
        <div class="mt-10">
          <a-space>
            <a-button type="primary" @click="saveDataClick(1)">保存</a-button>
            <a-button v-if="!toOneShow()" @click="saveDataClick(2)">保存并合并单元格</a-button>
          </a-space>
        </div>
      </div>
    </a-drawer>
    <div style="padding: 15px; background: #f5f5f5; margin-bottom: 15px">
      <a-space>
        <div>
          <a-space>
            <div><b>名称</b></div>
            <div>
              <a-input v-model="create_info.name" style="width: 300px;"></a-input>
            </div>
          </a-space>
        </div>
        <div class="ml-4">
          <a-space>
            <div><b>备注</b></div>
            <div>
              <a-input v-model="create_info.remark" style="width: 500px;"></a-input>
            </div>
          </a-space>
        </div>
        <div class="ml-5">
          <a-button @click="createTplClick()" type="primary">保存名称和备注</a-button>
        </div>
      </a-space>
    </div>

    <div class="excel_wrapper">
      <div class="button_wrapper">
        <a-space>
          <!--          <button v-if="table_type" @click="changeEditType()" class="layui-btn-xs layui-btn">切换为{{-->
          <!--              edit === 1 ? '区域' : '数据'-->
          <!--            }}编辑模式-->
          <!--          </button>-->
          <button v-if="edit === 1" class="layui-btn-xs layui-btn upload_wrapper">
            上传本地Excel
            <input type="file" @change="uploadExcel" class="upload_input_wrapper">
          </button>
          <button v-if="edit === 1" @click="saveTableInfo()" class="layui-btn-xs layui-btn">保存表格结构</button>
          <!--          <button v-if="edit === 2" @click="setAreaToTh()" class="layui-btn-xs layui-btn-warm layui-btn">-->
          <!--            设置选定区域为表头-->
          <!--          </button>-->
          <!--          <button v-if="edit === 2 && area_info.th" @click="delArea(1)"-->
          <!--                  class="layui-btn-xs layui-btn-primary layui-btn">-->
          <!--            删除表头区域-->
          <!--          </button>-->
          <!--          <button v-if="edit === 2" @click="setAreaToTb()" class="layui-btn-xs layui-btn">设置选定区域为表尾</button>-->
          <!--          <button v-if="edit === 2 && area_info.tb" @click="delArea(2)"-->
          <!--                  class="layui-btn-xs layui-btn-primary layui-btn">-->
          <!--            删除表尾区域-->
          <!--          </button>-->
          <!--          <button v-if="edit === 2" @click="setAreaToTl()" class="layui-btn-xs layui-btn-normal layui-btn">设置选定区域为侧边-->
          <!--          </button>-->
          <!--          <button v-if="edit === 2 && area_info.tl" @click="delArea(3)"-->
          <!--                  class="layui-btn-xs layui-btn-primary layui-btn">-->
          <!--            删除侧边区域-->
          <!--          </button>-->

          <!--          <button v-if="edit === 2" @click="setAreaToBt()" class="layui-btn-xs layui-btn-danger layui-btn">设置选定区域为固定前页-->
          <!--          </button>-->
          <!--          <button v-if="edit === 2 && area_info.bt" @click="delArea(5)"-->
          <!--                  class="layui-btn-xs layui-btn-primary layui-btn">-->
          <!--            删除固定前页区域-->
          <!--          </button>-->
          <!--          <button v-if="edit === 2" @click="setAreaToBb()" class="layui-btn-xs layui-btn-danger layui-btn">设置选定区域为固定尾页-->
          <!--          </button>-->
          <!--          <button v-if="edit === 2 && area_info.bb" @click="delArea(6)"-->
          <!--                  class="layui-btn-xs layui-btn-primary layui-btn">-->
          <!--            删除固定尾页区域-->
          <!--          </button>-->
          <button v-if="edit === 1 && table_type" @click="setItemData()"
                  class="layui-btn-xs layui-btn">
            设置单元格数据
          </button>
          <button v-if="edit === 1 && table_type" @click="getTableDataList(true)"
                  class="layui-btn-xs layui-btn">
            单元格数据列表
          </button>
        </a-space>
      </div>
      <div class="the_table_wrapper">

        <div id="luckysheet" class="luckysheet_wrapper"></div>
        <!--        yp_content_turn-->
        <div class="yp_set_wrapper">
          <a-tabs v-model="activeKey" size="small">
            <a-tab-pane :key="1" tab="仪器回填">
              <table class="layui-table" lay-size="sm" style="margin-top: 0;width: 700px;">
                <thead>
                <tr>
                  <th width="100">数据读取</th>
                  <th width="200">名称</th>
                  <th width="200">标识</th>
                  <th width="80">排序</th>
                  <th>操作</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ returnYpcontentXy(itemyiqi_create.range) }}</td>
                  <td>
                    <a-input v-model="itemyiqi_create.name"></a-input>
                  </td>
                  <td>
                    <a-input v-model="itemyiqi_create.mark"></a-input>
                  </td>
                  <td>
                    <a-input v-model="itemyiqi_create.order"></a-input>
                  </td>
                  <td>
                    <a-space>
                      <button @click="itemyiqiReadRange()" class="layui-btn-xs layui-btn-primary layui-btn">更新位置
                      </button>
                      <button @click="createItemyiqiRange()" class="layui-btn-xs layui-btn-primary layui-btn">添加
                      </button>
                      <button class="layui-btn-xs layui-btn-primary layui-btn" style="opacity: 0;cursor:default;">添加
                      </button>
                    </a-space>
                  </td>
                </tr>
                <tr v-for="(itemi,itemk) in itemyiqi_list" :key="itemk">
                  <td>{{ returnYpcontentXy(itemi.range) }}</td>
                  <td>
                    <a-input v-model="itemi.name"></a-input>
                  </td>
                  <td>
                    <a-input v-model="itemi.mark"></a-input>
                  </td>
                  <td>
                    <a-input v-model="itemi.order"></a-input>
                  </td>
                  <td>
                    <a-space>
                      <button @click="itemyiqiListReadRange(itemk)" class="layui-btn-xs layui-btn-primary layui-btn">
                        更新位置
                      </button>
                      <button @click="createItemyiqiListRange(itemk)" class="layui-btn-xs layui-btn-primary layui-btn">
                        保存
                      </button>
                      <button @click="delItemyiqiListRange(itemk)" class="layui-btn-xs layui-btn-primary layui-btn">删除
                      </button>
                    </a-space>
                  </td>
                </tr>
                </tbody>
              </table>
            </a-tab-pane>
            <a-tab-pane :key="2" tab="原始记录单回填">
              <div>
                <table class="layui-table" lay-size="sm" style="width: 300px;">
                  <tbody>
                  <tr>
                    <td style="width: 100px;">
                      <span v-if="area6_info">{{ returnYpcontentXy(area6_info.area) }}</span>
                    </td>
                    <td>
                      <button @click="setAreaToLoop()" class="layui-btn-xs layui-btn-warm layui-btn">
                        设置选区为循环区域
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table class="layui-table" lay-size="sm" style="width: 500px;">
                  <thead>
                  <tr>
                    <th>对应单元格</th>
                    <th>回填数据</th>
                    <th>操作</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td style="width: 100px;">
                      {{ returnYpcontentXy(create_loop.area) }}
                    </td>
                    <td>
                      <a-select v-model="create_loop.type" placeholder="请选择模块" style="width: 100px">
                        <a-select-option :value="1">
                          检测项目
                        </a-select-option>
                        <a-select-option :value="2">
                          检测结果
                        </a-select-option>
                        <a-select-option :value="3">
                          限值
                        </a-select-option>
                        <a-select-option :value="4">
                          检测结果
                        </a-select-option>
                      </a-select>
                    </td>
                    <td>
                      <button class="layui-btn layui-btn-primary layui-btn-xs" @click="readarealoop()">读取选区</button>
                      <button class="layui-btn layui-btn-primary layui-btn-xs" @click="createarealoop()">添加</button>
                    </td>
                  </tr>
                  <tr v-for="(li,lk) in loop_list">
                    <td style="width: 100px;">
                      {{ returnYpcontentXy(li.area) }}
                    </td>
                    <td>
                      <a-select v-model="li.type" placeholder="请选择模块" style="width: 100px">
                        <a-select-option :value="1">
                          检测项目
                        </a-select-option>
                        <a-select-option :value="2">
                          检测结果
                        </a-select-option>
                        <a-select-option :value="3">
                          限值
                        </a-select-option>
                        <a-select-option :value="4">
                          检测结果
                        </a-select-option>
                      </a-select>
                    </td>
                    <td>
                      <button class="layui-btn layui-btn-primary layui-btn-xs" @click="readarealineloop(lk)">读取选区
                      </button>
                      <button class="layui-btn layui-btn-primary layui-btn-xs" @click="updatearealoop(li)">保存</button>
                      <button class="layui-btn layui-btn-primary layui-btn-xs" @click="delarealoop(li)">删除</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <!--                <table class="layui-table" lay-size="sm" style="width: 700px;">-->
                <!--                  <thead>-->
                <!--                  <tr>-->
                <!--                    <th width="100px">目标位置</th>-->
                <!--                    <th>检测项目</th>-->
                <!--                    <th>值</th>-->
                <!--                    <th>操作</th>-->
                <!--                  </tr>-->
                <!--                  </thead>-->
                <!--                  <tbody>-->
                <!--                  <tr v-for="(li,lk) in report_item_list" :key="lk">-->
                <!--                    <td>-->
                <!--                      {{ returnYpcontentXy(li.range) }}-->
                <!--                    </td>-->
                <!--                    <td>-->
                <!--                      <a-cascader style="width: 200px;" :options="check_item_item_list"-->
                <!--                                  :disabled="true"-->
                <!--                                  :fieldNames="{ label: 'name', value: 'id', children: 'children' }"-->
                <!--                                  v-model="li.item_id"/>-->
                <!--                    </td>-->
                <!--                    <td>-->
                <!--                      {{ li.value_name }}-->
                <!--                    </td>-->
                <!--                    <td>-->
                <!--                      <a-space>-->
                <!--                        <a-button @click="chooseRangeedit(lk)">选择位置</a-button>-->
                <!--                        <a-button @click="editItemTplData(lk)">保存数据</a-button>-->
                <!--                        <a-button @click="delItemTplData(lk)">删除数据</a-button>-->
                <!--                      </a-space>-->
                <!--                    </td>-->
                <!--                  </tr>-->
                <!--                  <tr>-->
                <!--                    <td>-->
                <!--                      {{ returnYpcontentXy(check_item_item_create.range) }}-->
                <!--                    </td>-->
                <!--                    <td>-->
                <!--                      <a-cascader style="width: 200px;" :options="check_item_item_list"-->
                <!--                                  @change="checkItemItemListUpdate"-->
                <!--                                  :fieldNames="{ label: 'name', value: 'id', children: 'children' }"-->
                <!--                                  v-model="check_item_item_create.item_id"-->
                <!--                                  placeholder="请选择数据类型"/>-->
                <!--                    </td>-->
                <!--                    <td>-->
                <!--                      <a-select style="width: 200px;" size="small" v-model="check_item_item_create.value">-->
                <!--                        <a-select-option v-for="(mi,mk) in check_item_tpl_data" :value="mi.id">-->
                <!--                          {{ mi.tpl_name }} <b>{{ mi.name }}</b>-->
                <!--                        </a-select-option>-->
                <!--                      </a-select>-->
                <!--                    </td>-->
                <!--                    <td>-->
                <!--                      <a-space>-->
                <!--                        <a-button @click="chooseRange()">选择位置</a-button>-->
                <!--                        <a-button @click="addItemTplData()">添加数据</a-button>-->
                <!--                      </a-space>-->
                <!--                    </td>-->
                <!--                  </tr>-->
                <!--                  </tbody>-->
                <!--                </table>-->

              </div>
            </a-tab-pane>
          </a-tabs>

          <!--          <PushC></PushC>-->
        </div>
      </div>

    </div>
  </div>
</template>
<style>
.ant-tabs-bar {
  margin: 0;
}
</style>
<style scoped>
.the_table_wrapper {
  width: 1500px;
  position: absolute;
  top: 25px;
  bottom: 0;
  left: 0;
}

.yp_set_wrapper {
  position: absolute;
  left: 820px;
  bottom: 0;
  top: -32px;
}

.upload_input_wrapper {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  cursor: pointer;
}

.upload_wrapper {
  position: relative;
}

.luckysheet_wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  width: 800px;
}

.excel_wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 60px;
  right: 10px;
}
</style>
