<script>
export default {
  name: "create",
  data: () => {
    return {
      create_info: {
        name: '',
        remark: ''
      },
      edit: 1,
      list_search: {
        page: 1,
        search: ''
      }
    }
  },
  mounted() {
    this.mountedDo();
  },
  methods: {
    mountedDo() {
      this.list_search.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
      this.list_search.search = this.$route.query.search ? this.$route.query.search : '';
      // 读取完成之后 开始执行 获取 任务委托单列表的函数
    },
    routerPush(page, search, id, edit) {
      // router 推送
      // page 页数
      // search 搜索名称或者编号
      // id 编辑的 任务ID
      // edit 编辑类型  1-基础信息 2-样品信息
      this.$router.push({
        name: 'jcbgtpl',
        params: {
          id
        },
        query: {
          page, search, edit, time: new Date() / 1
        }
      })
    },
    createTplClick() {
      this.$sa0.post({
        url: this.$api('创建报告模板'), data: this.create_info
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            // 按照 接口返回的数据 给本地的变量 赋值
            this.routerPush(this.list_search.page, this.list_search.search, response.data.id, this.edit)
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

  }
}
</script>
<template>
  <div style="padding: 15px; background: #f5f5f5; margin-bottom: 15px">
    <a-space>
      <div>
        <a-space>
          <div><b>名称</b></div>
          <div>
            <a-input v-model="create_info.name" style="width: 300px;"></a-input>
          </div>
        </a-space>
      </div>
      <div class="ml-4">
        <a-space>
          <div><b>备注</b></div>
          <div>
            <a-input v-model="create_info.remark" style="width: 500px;"></a-input>
          </div>
        </a-space>
      </div>
      <div class="ml-5">
        <a-button @click="createTplClick()" type="primary">创建模板</a-button>
      </div>
    </a-space>
  </div>
</template>
<style scoped>

</style>
