<script>
/**
 * name：推送数据
 * user：sa0ChunLuyu
 * date：2022-01-20 13:45:34
 */
export default {
  data() {
    return {
      push_data: [],
      column_table: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      create_data: {
        range: false,
        type: 1,
        data: [0, 0],
      }
    }
  },
  mounted() {
    this.getPushData();
  },
  methods: {
    saveItem(item){
      if (!item.range) return layer.msg('请选择位置')
      if (Number(item.data[0]) <= 0 || Number(item.data[1]) <= 0) return layer.msg('请填写位置')
      this.$sa0.post({
        url: this.$api('编辑报告返回数据'),
        data: {
          id: item.id,
          range: JSON.stringify(item.range),
          type: item.type,
          data: JSON.stringify(item.data),
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.getPushData()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    delItem(id){
      this.$sa0.post({
        url: this.$api('删除报告返回数据'),
        data: {
          id: id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.getPushData()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getPushData() {
      this.$sa0.post({
        url: this.$api('获取报告返回数据'),
        data: {
          id: this.$route.params.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.push_data = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    saveRange() {
      // 设置报告返回数据
      if (!this.create_data.range) return layer.msg('请选择位置')
      if (Number(this.create_data.data[0]) <= 0 || Number(this.create_data.data[1]) <= 0) return layer.msg('请填写位置')
      this.$sa0.post({
        url: this.$api('设置报告返回数据'),
        data: {
          id: this.$route.params.id,
          range: JSON.stringify(this.create_data.range),
          type: this.create_data.type,
          data: JSON.stringify(this.create_data.data),
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.create_data = {
              range: false,
              type: 1,
              data: [0, 0],
            }
            this.getPushData()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    setRange(key) {
      if (key === -1) {
        this.create_data.range = this.getRange()
      } else {

      }
    },
    getRange() {
      let range = luckysheet.getRange()
      return range[0]
    },
    dateRangeNameForLine(range) {
      if (!range) return '';
      let t1 = `${this.column_table[range.column[0]]}${range.row[0] + 1}`;
      let t2 = `${this.column_table[range.column[1]]}${range.row[1] + 1}`;
      if (t1 == t2) {
        return t1;
      } else {
        return `${t1}-${t2}`;
      }
    },
  }
}
</script>
<template>
  <div>
    <table class="layui-table">
      <thead>
      <tr>
        <th>位置</th>
        <th>类型</th>
        <th>数据</th>
        <th>操作</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,key) in push_data">
        <td>{{ dateRangeNameForLine(item.range) }}</td>
        <td>
          <a-select v-model="item.type">
            <a-select-option :value="1">数据</a-select-option>
            <a-select-option :value="2">计算</a-select-option>
          </a-select>
        </td>
        <td>
          <div>
            <a-space>
              第
              <a-input v-model="item.data[0]" style="width: 40px;"/>
              组
            </a-space>
          </div>
          <div style="margin-top: 10px">
            <a-space>
              第
              <a-input v-model="item.data[1]" style="width: 40px;"/>
              个
            </a-space>
          </div>
        </td>
        <td>
          <a-space>
            <a-button @click="setRange(item.id)" size="small">更新坐标</a-button>
            <a-button @click="saveItem(item)" size="small">保存</a-button>
            <a-button @click="delItem(item.id)" size="small">删除</a-button>
          </a-space>
        </td>
      </tr>
      <tr>
        <td>{{ dateRangeNameForLine(create_data.range) }}</td>
        <td>
          <a-select v-model="create_data.type">
            <a-select-option :value="1">数据</a-select-option>
            <a-select-option :value="2">计算</a-select-option>
          </a-select>
        </td>
        <td>
          <div>
            <a-space>
              第
              <a-input v-model="create_data.data[0]" style="width: 40px;"/>
              组
            </a-space>
          </div>
          <div style="margin-top: 10px">
            <a-space>
              第
              <a-input v-model="create_data.data[1]" style="width: 40px;"/>
              个
            </a-space>
          </div>
        </td>
        <td>
          <a-space>
            <a-button @click="setRange(-1)" size="small">{{ create_data.range ? '更新' : '获取' }}坐标</a-button>
            <a-button @click="saveRange()" size="small">保存</a-button>
          </a-space>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>

</style>
