<script>
import ListComponent from './list/list.vue'
import EditComponent from './edit/edit.vue'

export default {
  name: "jcbgtpl",
  components: {
    ListComponent, EditComponent
  }
}
</script>
<template>
  <div>
    <ListComponent></ListComponent>
    <EditComponent></EditComponent>
  </div>
</template>
<style scoped>

</style>
