<script>
import CreateComponent from './create.vue'
import UpdateComponent from './update.vue'

export default {
  components: {
    CreateComponent,
    UpdateComponent
  },
  name: "edit"
}
</script>
<template>
  <div class="edit_wrapper">
    <CreateComponent v-if="Number($route.params.id) === 0"></CreateComponent>
    <UpdateComponent v-if="Number($route.params.id) !== 0"></UpdateComponent>
  </div>
</template>
<style scoped>
.edit_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 300px;
  right: 0;
}
</style>
